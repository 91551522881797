import { IconButton } from "@global";
import { Maybe, SanityRecipe, SanityRecipeInfo } from "@graphql-types";
import { Container, P } from "@util/standard";
import { navigate } from "gatsby-link";

import * as React from "react";

interface Props {
  recipe: SanityRecipe;
}

const RecipeTags = (props: Props) => {
  const { recipe } = props;
  if (recipe === null || recipe === undefined) {
    return null;
  }

  const recipeInfo: Maybe<SanityRecipeInfo> | undefined = recipe.info;
  if (recipeInfo === null || recipeInfo === undefined) {
    return null;
  }

  return (
    <Container
      width="100%"
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="center"
    >
      <IconButton icon="tags" />
      {recipeInfo.tags &&
        recipeInfo.tags.map((tag, index) => {
          return (
            tag &&
            tag.main && (
              <a
                onClick={() =>
                  navigate(
                    `/recipes/${
                      tag?.main?.slug?.current ? tag.main.slug.current : ""
                    }`
                  )
                }
                key={tag.id}
              >
                <P margin="0px 10px 0px 0px">
                  {tag.main.title}
                  {index < recipeInfo.tags.length - 1 ? "," : ""}
                </P>
              </a>
            )
          );
        })}
    </Container>
  );
};

export default RecipeTags;
