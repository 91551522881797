import * as React from "react";

import { Image } from "@global";
import { Maybe, SanityAllergen } from "@graphql-types";
import { Container, P, FlexGrid } from "@util/standard";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  allergens?: Maybe<Maybe<SanityAllergen>[]> | undefined;
}

const StyledP = styled(P)`
  font-size: 13px;
  margin: 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 11px;
  }
`;

const AllergenImage = styled.div`
  width: 24px;
  margin-right: 6px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 16px;
  }
`;

const AllergenTags = (props: Props) => {
  const { allergens } = props;
  if (!allergens || allergens.length < 1) return null;
  const { isMobileWidth } = useCheckScreenWidth();

  const allergenWidth = () => {
    switch (allergens.length) {
      case 1:
        return "100%";
      case 2:
        return "50%";
      default:
        return "50%";
    }
  };

  return (
    <Container
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="flex-start"
      margin=".8em 0 0"
      tabletMargin=".8em 0 0"
    >
      <FlexGrid gridMargin="20px" mobileGridMargin="10px">
        {allergens.map(allergen => {
          if (!allergen) return;
          return (
            <Container
              width={
                allergen.title?.length && allergen.title.length > 12
                  ? "50%"
                  : allergenWidth()
              }
              key={allergen.slug?.current}
              alignItems="center"
              margin="0 0 10px"
            >
              <AllergenImage>
                <Image imageData={allergen.icon?.asset?.gatsbyImageData} />
              </AllergenImage>
              <StyledP>{allergen.title}</StyledP>
            </Container>
          );
        })}
      </FlexGrid>
    </Container>
  );
};

export default AllergenTags;
